import React, { useEffect, useRef, useState } from 'react'
import { Toolbar } from './components/Toolbar'
import { HomeScreen } from './components/screens/HomeScreen'
import { LandingScreen } from './components/screens/LandingScreen'
import { ArcadeScreen } from './components/screens/ArcadeScreen'
import { EndcardScreen } from './components/screens/EndcardScreen'
import { Content } from './components/composable/Content'
import { AppPresenter } from './domain/AppPresenter'
import { NavigationState } from './domain/entities/NavigationState'
import { Screens } from './domain/constants/Screens'
import { useParams } from 'react-router-dom'
import '../src/assets/css/toolbar.css'
import '../src/assets/css/App.css'
import '../src/assets/css/arcade.css'
import '../src/assets/css/carousel.css'
import '../src/assets/css/endcard.css'
import '../src/assets/css/fonts.css'
import '../src/assets/css/home.css'
import '../src/assets/css/icons.css'
import '../src/assets/css/index.css'

export default function App () {
    const [giftActive, setGiftActive] = useState(false)
    const [registered, setRegistered] = useState(false)
    const [navigationState, setNavigationState] = useState(new NavigationState(Screens.home, '', 0))
    const [playAnimation, setPlayAnimation] = useState(false)
    const [navigateFromArcade, setNavigateFromArcade] = useState(true)
    const [ready, setReady] = useState(false)
    const [language, selectedOptionLanguage] = useState(navigator.language.split('-')[0])

    const presenter = useRef(
        new AppPresenter(
            useParams().campaignId,
            setRegistered,
            setPlayAnimation,
            setGiftActive,
            setNavigateFromArcade,
            setNavigationState,
            setReady,
            selectedOptionLanguage,
            language
        )).current

    useEffect(() => {
        window.addEventListener('click', playBGM)
        presenter.initialize()
    }, [])

    const playBGM = () => {
        window.removeEventListener('click', playBGM)
        presenter.playBGM()
    }

    const userNotRegistered = () => !registered

    const resetAnimations = () => {
        setPlayAnimation(false)
        setNavigateFromArcade(false)
    }

    const stopAnimation = () => setPlayAnimation(false)

    const renderContent = () => {
        if (userNotRegistered()) {
            return landingScreen()
        } else if (navigationState.isHome()) {
            return homeScreen()
        } else if (navigationState.isPlaying()) {
            return arcadeScreen()
        } else if (navigationState.isEndcard()) {
            return endCardScreen()
        } else {
            return renderLoading()
        }
    }

    const homeScreen = () =>
        <HomeScreen
            configuration={presenter.getHomeScreenConfiguration(language)}
            repository={presenter.localRepository}
            initialSlide={navigationState.initialSlide}
            arcades={presenter.getArcadeConfigurations(language)}
            navigate={navigateFromArcade}
            play={presenter.play}
            showEndcard={presenter.showEndCard}
            triggerTrophyAnimations={playAnimation}
            resetAnimations={resetAnimations}
            resetTrophyAnimation={stopAnimation}
        />

    const arcadeScreen = () =>
        <ArcadeScreen arcadeSource={navigationState.link} back={presenter.complete}/>

    const endCardScreen = () =>
        <EndcardScreen
            configuration={presenter.getEndCardScreenConfiguration(language)}
            back={presenter.back}
            style={presenter.style('endcard.css')}
        />

    const landingScreen = () =>
        <LandingScreen
            configuration={presenter.getLandingConfiguration(language)}
            register={presenter.register}
        />

    const renderApp = () =>
        <div className="App background-eterland">
            <link href={presenter.style('App.css')} rel="stylesheet"/>
            <link href={presenter.style('index.css')} rel="stylesheet"/>
            <link href={presenter.style('fonts.css')} rel="stylesheet"/>
            <link href={presenter.style('endcard.css')} rel="stylesheet"/>
            <link href={presenter.style('icons.css')} rel="stylesheet"/>
            <link href={presenter.style('arcade.css')} rel="stylesheet"/>
            <link href={presenter.style('toolbar.css')} rel="stylesheet"/>
            <link href={presenter.style('home.css')} rel="stylesheet"/>
            <link href={presenter.style('carousel.css')} rel="stylesheet"/>
            <Toolbar
                isBackEnabled={navigationState.isPlaying() || navigationState.isEndcard()}
                back={presenter.back}
                configuration={presenter.getToolbarConfiguration()}
                repository={presenter.localRepository}
                options={ presenter.getLanguageConfiguration()}
                presenter={ presenter}
                defaultLanguage={language}
            />
            {
                navigationState.isEndcard() && <div className="endcard-overlay"/>
            }
            <div className={giftActive ? 'fade' : ''}/>
            <Content>
                {renderContent()}
            </Content>
            <div className="footer">
                <div id="powered-by-etermax"/>
            </div>
        </div>

    const renderLoading = () => <div></div>

    return ready ? renderApp() : renderLoading()
}
