import React, { useEffect, useState } from 'react'
import { ToolbarProps } from './props/ToolbarProps'
import { AudioService } from '../delivery/services/AudioService'
import DropDownButton from './DropDownButton'

export function Toolbar (props: ToolbarProps) {
    const [muted, setMuted] = useState(props.repository.getMuted())

    useEffect(() => {
        AudioService.setMuted(muted)
    }, [])

    return <div className="toolbar">
        <div className="flex-row">
            <div className={`${muted ? 'sound-off' : 'sound-on'}`}
                onClick={() => {
                    AudioService.setMuted(!muted)
                    setMuted(!muted)
                    props.repository.setMuted(!muted)
                }}>
                <div className={`invisible ${!muted ? 'sound-off' : 'sound-on'}`}/>
            </div>
            <div>
                {
                    props.options.length > 1 && <DropDownButton
                        options={props.options}
                        onSelect={(option) => { setOptionClicked(option) }}
                        defaultLanguage={props.defaultLanguage}
                    />
                }
            </div>
        </div>
        <div className="eterland-logo"></div>
    </div>

    function setOptionClicked (option:string) {
        props.presenter.setLanguageConfiguration(option)
    }
}
