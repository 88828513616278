import React, { useState } from 'react'
import { ListChildProps } from './props/ListChildProps'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import CustomButton from './CustomButton'

export function CarouselComponent (props: ListChildProps) {
    const [itemIndex, setItemIndex] = useState(0)
    const speedMs = 220
    const thresholdPixels = 25
    console.log('itemIndex = ' + props.children.length)
    const isActive = (index: number) => index === itemIndex
    const isBesideActive = (index: number) =>
        Math.abs(index - itemIndex) === 1 ||
        (index === 0 && itemIndex === props.children.length - 1) ||
        (index === props.children.length - 1 && itemIndex === 0)

    const isBesideActiveRight = (index: number) =>
        itemIndex - index === 2 ||
        (index === props.children.length - 2 && itemIndex === 0) ||
        (index === props.children.length - 1 && itemIndex === 1)

    const isBesideActiveLeft = (index: number) =>
        index - itemIndex === 2 ||
        (index === 0 && itemIndex === props.children.length - 2) ||
        (index === 1 && itemIndex === props.children.length - 1)

    const onSlideChange = (swiperIndex:number) => {
        setItemIndex(swiperIndex)
        props.onSlideChange()
    }

    const previousArrow = () => <div key={'previous'} className="carousel-scroll-icon previous-icon"/>

    const nextArrow = () => <div key={'next'} className="carousel-scroll-icon next-icon"/>

    return <div className="full-size">
        {
            props.children.length !== 1
                ? (
                    <Swiper
                        slidesPerView={'auto'}
                        speed={speedMs}
                        threshold={thresholdPixels}
                        spaceBetween={10}
                        modules={[Navigation]}
                        loop={true}
                        centeredSlides={true}
                        navigation={{
                            prevEl: '.previous',
                            nextEl: '.next'
                        }}
                        onSlideChange={(swiper) => onSlideChange(swiper.realIndex)}
                        initialSlide={props.initialSlide}
                    >
                        {
                            props.children.map((child, index) => {
                                return <SwiperSlide
                                    key={index}
                                    className={`item-container ${isActive(index) ? 'item-button-active' : isBesideActive(index) ? 'item-inactive-container' : isBesideActiveRight(index) ? 'item-far-inactive-container-left' : isBesideActiveLeft(index) ? 'item-far-inactive-container-right' : 'item-far-inactive-container-left'}`}>
                                    <div className={`full-size item ${isActive(index) ? 'item-active' : ''}`}>{child}</div>
                                </SwiperSlide>
                            })
                        }
                        <CustomButton className="previous" renderChildren={[previousArrow()]} />
                        <CustomButton className="next" renderChildren={[nextArrow()]} />
                    </Swiper>
                )
                : (
                    <div className='full-size-one-players'>
                        {props.children.map((child, index) => {
                            return <SwiperSlide
                                key={index}
                                className={`item-container ${isActive(index) ? 'item-button-active' : isBesideActive(index) ? 'item-inactive-container' : isBesideActiveRight(index) ? 'item-far-inactive-container-left' : isBesideActiveLeft(index) ? 'item-far-inactive-container-right' : 'item-far-inactive-container-left'}`}>
                                <div className={`item ${isActive(index) ? 'item-active' : ''}`}>{child}</div>
                            </SwiperSlide>
                        })}
                    </div>
                )
        }
    </div>
}
