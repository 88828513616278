import React, { useState } from 'react'
import { Screen } from '../composable/Screen'
import { LandingProps } from '../props/LandingProps'
import CustomButton from '../CustomButton'
import KeyValuePair from '../../domain/configurations/KeyValuePair'

export function LandingScreen (props: LandingProps) {
    const mailFormatRegex = /^([a-zA-Z0-9._-]+)@([a-zA-Z0-9._-]+)\.([a-zA-Z0-9._-]+)$/i
    const [fields, setFields] = useState(() => props.configuration.fields.map(field => new KeyValuePair(field.name, '')))

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = parseInt(event.target.dataset.index || '0')
        const newFields = [...fields]
        newFields[index] = new KeyValuePair(newFields[index].key, event.target.value.trim())
        setFields(newFields)
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && !invalidMailFormat() && !hasEmptyFields()) {
            props.register(fields)
        }
    }

    const hasEmptyFields = () => fields.filter(field => field.value === '').length > 0

    const invalidMailFormat = () => {
        const mailField = fields.filter(field => field.key === 'mail')
        if (mailField.length > 0) {
            return !mailFormatRegex.test(mailField[0].value.toLowerCase())
        } else {
            return false
        }
    }

    return (
        <Screen>
            <div className="logo" id="client-logo"/>
            <div className="container">
                <div className="line bold title">
                    ¡JUGÁ Y DESCUBRÍ TODO<br/>
                    LO QUE TENEMOS PARA VOS!
                    {/* {props.configuration.title} */}
                </div>
                <div className="logo" id="landing-gift"/>
                {
                    props.configuration.fields.map((field, index) => {
                        return <React.Fragment key={index}>
                            <div className="line description">
                                INGRESÁ TU E-MAIL<br/>
                                PARA PARTICIPAR<br/>
                                DE ESTA EXPERIENCIA.
                                {/* {field.label} */}
                            </div>
                            <input
                                data-index={index}
                                type="text"
                                name={field.name}
                                placeholder={field.hint}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                autoCorrect={'false'}
                            />
                        </React.Fragment>
                    })
                }
                <CustomButton
                    disabled={hasEmptyFields() || invalidMailFormat()}
                    className="wide-button"
                    onClick={() => props.register(fields)}
                    text="Continuar"// {props.configuration.buttonText}
                />
            </div>
        </Screen>
    )
}
