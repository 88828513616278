import React from 'react'
import { ArcadeProps } from './props/ArcadeProps'
import CustomButton from './CustomButton'

export function ArcadeComponent (props: ArcadeProps) {
    const play = () => props.play(props.configuration.link)

    function playTrophyAnimation () {
        if (props.playTrophyAnimation) { setTimeout(props.resetTrophyAnimation, 1000) }
        return props.playTrophyAnimation
    }

    const ownedTrophy = () => `arcade-trophy  ${playTrophyAnimation() ? 'arcade-trophy-animate' : ''}`

    return <div className="full-size arcade">
        <div className="arcade-data">
            <img className="arcade-thumbnail" src={`${props.configuration.thumbnailUrl}`}/>
            <div className="line title bold">{props.configuration.name}</div>
            <div className="description-container"> <div className="line arcade-description">Poné a prueba tu memoria<br/>encontrando los pares de perfumes.</div> </div>
            <div className={props.played ? ownedTrophy() : 'arcade-trophy-empty'}/>
        </div>
        <CustomButton
            className="wide-button-arcade arcade-button"
            onClick={play}
            text={props.buttonText}
        />
    </div>
}
