import React, { useEffect, useState } from 'react'
import { ArcadeComponent } from '../ArcadeComponent'
import { HomeScreenProps } from '../props/HomeScreenProps'
import { Screen } from '../composable/Screen'
import { CarouselComponent } from '../CarouselComponent'
import { ArcadeInfo } from '../../domain/entities/ArcadeInfo'
import CustomButton from '../CustomButton'

export function HomeScreen (props: HomeScreenProps) {
    const arcadesCount = props.arcades.length
    const [played, setPlayed] = useState<Set<string>>(new Set())

    useEffect(() => {
        setPlayed(props.repository.getPlayed)
    }, [])

    const isPlayed = (id: string) => played.has(id)

    const play = (link: string, id: string, name: string, index : number) => {
        const alreadyPlayed = isPlayed(id)
        played.add(id)
        setPlayed(played)
        props.repository.setPlayed(played)
        props.play(new ArcadeInfo(link, name, alreadyPlayed, index), played.size)
        props.resetAnimations()
    }

    const allArcadesCompleted = () => played.size === arcadesCount

    return (
        <Screen>
            <div className="logo" id="client-logo"/>
            <div className="scroll-container">
                <CarouselComponent onSlideChange={() => {}}
                    initialSlide={props.initialSlide}>
                    {
                        props.arcades.map((config, index) => {
                            return (
                                <ArcadeComponent
                                    key={index}
                                    played={isPlayed(config.id)}
                                    play={(link) => play(link, config.id, config.name, index)}
                                    configuration={config}
                                    playTrophyAnimation={props.triggerTrophyAnimations}
                                    resetTrophyAnimation={props.resetTrophyAnimation}
                                    buttonText={props.configuration.arcadeButtonText}
                                />)
                        })
                    }
                </CarouselComponent>
            </div>
            <div className="home-screen-descriptions">¡JUGÁ Y DESCUBRÍ LAS FRAGANCIAS!</div>
            {allArcadesCompleted() && props.navigate
                ? <>
                    <div className="reward-contxainer">
                        <CustomButton
                            className='wide-button'
                            onClick={props.showEndcard}
                            text={props.configuration.rewardButtonText}
                        />
                    </div>
                    { !props.repository.getGiftClaimed() &&
                        <>
                            <div className="gift-animation"/>
                            {
                                setTimeout(() => {
                                    props.showEndcard()
                                }, 4000)
                            }
                        </>
                    }
                </>
                : props.arcades.length > 1 && <div id="home-screen-reward-container" className="flex-column">
                    <div id="home-screen-reward-message"
                        className="message right-align">{props.configuration.message}</div>
                    <div className="gift-container">
                        {
                            props.arcades.map((value, index) =>
                                index < played.size ? <div className="gift-progress-active"/> : <div className="gift-progress-disabled"/>
                            )
                        }
                    </div>
                </div>
            }
        </Screen>
    )
}
